import React, { useState, useEffect, useRef, useCallback } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import { Row, Col } from 'emotion-flex';
import Image from 'gatsby-image';
import Container from './Container';
import { MonoFontLabel } from '../common/typography';
import Text from './Text';
import presets from '../../styles/presets';
import { ReactComponent as PhoneFrame } from '../../assets/images/phone-frame.svg';
import { ContentfulContentBlock } from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';

const Dot = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  background-color: #489f9d;
  border-radius: 50%;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  margin-bottom: 25px;
  transition: opacity 0.5s ease-in;
  cursor: pointer;
`;

export default function Tracking({ items }: ContentfulContentBlock) {
  const [activeIndex, setActiveIndex] = useState(0);
  const intervalRef = useRef(null);
  const itemsWithImages = items.filter((item) => item.image);
  const DURATION = 4000;

  const getColor = useCallback((index: number) => {
    switch (index) {
      case 0:
        return '#FFDAD5';
      case 1:
        return '#608BE4';
      case 2:
        return '#9775C1';
      default:
        return '#69B0AE';
    }
  }, []);

  const transitionToNextSlide = useCallback(() => {
    if (itemsWithImages.length > 1) {
      setActiveIndex((i) => (i + 1) % itemsWithImages.length);
    }
  }, [itemsWithImages]);

  const goToSlide = useCallback(
    (index: number) => {
      if (activeIndex !== index) {
        // reset existing interval if present
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }

        setActiveIndex(index);

        intervalRef.current = setInterval(transitionToNextSlide, DURATION);
      }
    },
    [transitionToNextSlide, activeIndex]
  );

  useEffect(() => {
    intervalRef.current = setInterval(transitionToNextSlide, DURATION);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [transitionToNextSlide]);

  return (
    <Container css={{ paddingTop: 48 }}>
      <Row css={{ alignItems: 'center' }}>
        <Col
          xs={12}
          lg={7}
          lgOffset={1}
          css={{
            marginBottom: 84,
            [presets.lg]: {
              marginBottom: 0,
            },
          }}
        >
          <div
            css={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              minHeight: '500px',
              [presets.lg]: {
                minHeight: '1042px',
              },
            }}
          >
            <Background
              fill={getColor(activeIndex)}
              css={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: 500,
                [presets.lg]: {
                  height: 1042,
                },
              }}
            />
            <PhoneFrame
              width={274}
              height={601}
              css={{
                position: 'absolute',
                width: 273,
                height: 601,
                left: '50%',
                marginLeft: -137, // width / 2
                top: '50%',
                marginTop: -294,
                zIndex: 2,
              }}
            />
            {itemsWithImages.map((item, i) => (
              <div
                key={item.id + i}
                css={[
                  {
                    opacity: 0,
                    position: 'absolute',
                    width: 274,
                    height: 588,
                    left: '50%',
                    marginLeft: -137, // width / 2
                    top: '50%',
                    marginTop: -287, // height / 2
                    zIndex: 1,
                    transition: 'opacity 1.5s ease-in',
                  },
                  i === activeIndex && {
                    opacity: 1,
                  },
                ]}
              >
                <Image
                  fluid={item.image.fluid}
                  css={{
                    width: 258,
                    marginLeft: 7,
                    marginTop: 16,
                    borderRadius: 20,
                  }}
                />
              </div>
            ))}
          </div>
          <div
            css={{
              display: 'none',
              visibility: 'hidden',
              [presets.lg]: {
                display: 'block',
                visibility: 'visible',
                position: 'absolute',
                top: '50%',
                right: 20,
                transform: 'translateY(-50%)',
              },
            }}
          >
            {itemsWithImages.map((item, i) => (
              <Dot
                key={`dot-${item.id}`}
                active={i === activeIndex}
                onClick={() => goToSlide(i)}
              />
            ))}
          </div>
        </Col>

        <Col xs={12} lg={4} lgOrder="first">
          <Global
            styles={css`
              .slide-in-fade-out-enter {
                opacity: 0;
                ${presets.lg} {
                  transform: translateY(64px);
                }
              }
              .slide-in-fade-out-exit {
                opacity: 1;
                transform: translateY(0);
              }
              .slide-in-fade-out-enter-active {
                opacity: 1;
                transform: translateY(0);
              }
              .slide-in-fade-out-exit-active {
                opacity: 0;
                transform: translateY(0);
              }
              .slide-in-fade-out-enter-active {
                transition: opacity 750ms ease-in-out, transform 750ms ease;
              }

              .slide-in-fade-out-exit-active {
                transition: opacity 400ms ease-out;
              }

              path#carousel-bg {
                transition: fill 750ms ease-out 400ms;
              }
            `}
          />
          <SwitchTransition mode="out-in">
            <CSSTransition<undefined>
              key={activeIndex}
              classNames="slide-in-fade-out"
              unmountOnExit
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
            >
              <div
                css={{
                  width: '100%',
                  textAlign: 'center',
                  maxWidth: 300,
                  margin: '0 auto',
                  [presets.md]: {
                    maxWidth: 'none',
                    margin: 0,
                    textAlign: 'left',
                  },
                }}
              >
                <MonoFontLabel>
                  {itemsWithImages[activeIndex].prefix}
                </MonoFontLabel>
                <h4
                  css={{
                    fontFamily: ['Recoleta Alt']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    fontSize: 32,
                    color: '#160B2C',
                  }}
                >
                  {itemsWithImages[activeIndex].heading}
                </h4>
                <Text>{itemsWithImages[activeIndex].shortText}</Text>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </Col>
      </Row>
    </Container>
  );
}

function Background({
  fill,
  ...rest
}: React.PropsWithChildren<{ fill: string }>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 860 1027" {...rest}>
      <g transform="translate(-9 -9)" fill="none" fillRule="evenodd">
        <path
          id="carousel-bg"
          d="M222.636 631.768c-59.638-167.166 42.42-395.582 216.17-452.207 30.429-10.247 100.16-19.202 132.303.928 47.596 29.809 18.167 128.477 60.624 237.13 46.474 118.654 178.422 133.091 162.594 245.627-11.663 82.92-72.157 119.055-128.963 138.188-171.861 58.075-385.894-10.378-442.728-169.666z"
          fill={fill}
          fillRule="nonzero"
        />
        <path
          d="M52.787 522.082c-2.66-2.025-2.614-6.493-2.614-6.493s4.36-1.185 7.022.84c2.662 2.027 2.614 6.494 2.614 6.494s-4.362 1.185-7.022-.841z"
          fill="#3B8381"
          fillRule="nonzero"
        />
        <path
          d="M57.537 513.316s-6.99 4.427-15.274 3.394"
          stroke="#000"
          strokeWidth=".72"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.15 510.613c2.448-16.857 25.136-23.08 34.77-12.619 8.215 8.922-1.416 18.028-1.416 18.028s6.698 11.984-3.507 18.097c-11.75 7.038-32.43-5.711-29.846-23.506z"
          fill="#69B0AE"
          fillRule="nonzero"
        />
        <path
          d="M506.32 63.818c-15.06 4.66-29.806-11.606-28.832-28.832.973-17.225 9.218-28.181 27.89-25.48 18.67 2.702 16 49.65.941 54.312z"
          fill="#F9D26F"
          fillRule="nonzero"
        />
        <path
          d="M640.104 81.561c-6.028-15.087-.548-26.946 16.44-35.578a16.8 16.8 0 0119.421 3.03c12.536 12.391 16.75 23.259 12.642 32.602-7.392 16.81-37.742 26.874-48.503-.054z"
          fill="#F0CBD6"
          fillRule="nonzero"
        />
        <path
          d="M668.338 72.092a6.857 6.857 0 01-13.612.082c-.365-2.837.274-4.895 1.916-6.173 3.969-3.088 12.6-1.688 11.696 6.09z"
          fill="#FFFDFC"
          fillRule="nonzero"
        />
        <path
          d="M668.205 97.819c2.445-7.792 7.852-19.982 16.777-20.395 3.544-.164 7.387 3.01 11.04 7.551M689.091 68.127c-5.286-.532-12.31-3.548-12.233-8.306.035-2.206.318-3.432 3.301-6.617M641.33 84.446c7.426-3.397 13.884-5.197 20.163 1.16 2.494 2.524 3.08 6.908 2.15 12.663M671.133 45.713c-4.589 10.437-23.896 22.662-32.257 17.445"
          stroke="#FFFDFC"
          strokeWidth=".9"
        />
        <g fill="#F9D26F" fillRule="nonzero">
          <path d="M181.92 206.183c-.443-.767-9.341 1.095-10.726.611-5.714-1.995-15.937-16.29-17.302-32.286-.597-6.988.424-18.246-.658-21.808-2.285-7.52-3.464-6.302-6.697-3.67-2.668 2.172-5.156 5.853-6.119 8.323-3.387 8.695-2.616 25.08 2.813 35.781s18.665 20.71 27.928 18.51c6.03-1.433 9.7-1.205 11.007-1.96.727-.42 1.316-.795-.246-3.501z" />
          <path d="M182.765 206.256c.014-.785-7.914-3.44-8.78-4.442-3.575-4.13-6.397-18.201.119-30.984 2.846-5.585 6.18-9.407 7.015-12.609 1.763-6.761 1.741-7.917-2.068-7.396-3.144.43-6.771 2.37-9.128 4.81-6.377 6.604-10.737 11.57-11.521 22.187-.785 10.617 4.875 25.098 13.253 27.662 5.453 1.67 8.25 3.521 9.642 3.546.776.013 1.42-.002 1.468-2.774z" />
        </g>
        <g fillRule="nonzero">
          <path
            d="M77.831 361.455c-11.425 5.928-31.057-1.768-36.987-13.777a16.194 16.194 0 01-1.739-6.736 15.055 15.055 0 01.378-3.796c1.181-5.169 7.919-6.21 15.306-10.33 8.066-4.506 9.966-13.03 17.512-11.405 1.432.306 2.822.793 4.136 1.45 3.837 1.916 6.748 5.166 8.731 9.087 6.015 11.859 3.55 29.859-7.337 35.507z"
            fill="#FFAFA4"
          />
          <path
            d="M65.916 345.367c-4.706 2.184-11.665-.75-13.157-5.258a5.526 5.526 0 01-.235-2.524c.067-.478.196-.955.383-1.418.773-1.93 3.393-2.3 6.454-3.819 3.342-1.661 4.6-4.843 7.359-4.214.524.119 1.02.305 1.477.554 1.334.728 2.233 1.952 2.738 3.423 1.534 4.452-.535 11.176-5.019 13.256z"
            fill="#FFFDFC"
          />
        </g>
        <path
          d="M320.387 95.135c7-4.54 10.719-11.684 17.722-21.368 7.003-9.685 11.664-13.365 7.09-20.398-5.647-8.687-16.854-3.454-21.857-2.314-14.157 3.227-22.16 5.48-27.558 9.915-5.4 4.434-11.349 14.135-2.169 27.179 8.884 12.623 19.773 11.526 26.772 6.986z"
          fill="#69B0AE"
          fillRule="nonzero"
        />
        <path
          d="M302.692 82.23c2.78 3.971 8.448 4.8 12.66 1.851 4.21-2.949 8.61-10.827 5.83-14.798-2.781-3.971-11.689-2.532-15.9.417s-5.371 8.559-2.59 12.53z"
          fill="#FFFDFC"
        />
        <path
          d="M70.255 685.008c11.757 8.616 19.058 11.967 23.938 6.644 4.88-5.324 2.038-10.532 2.083-25.672.045-15.14-19.65-19.749-28.46-11.199-8.81 8.55-9.318 21.612 2.439 30.227z"
          fill="#F9D26F"
          fillRule="nonzero"
        />
        <path
          d="M697.45 935.981c15.39 1.744 29.774 22.485 27.588 38.36a19.334 19.334 0 01-2.81 7.845 18.102 18.102 0 01-2.937 3.515c-4.664 4.341-12.084.877-22.228.035-11.082-.912-18.726 6.28-25.154-.37a20.661 20.661 0 01-3.146-4.2c-2.534-4.462-3.244-9.63-2.577-14.845 2-15.783 16.599-32.001 31.264-30.34z"
          fill="#608BE4"
          fillRule="nonzero"
          opacity=".099"
        />
        <path
          d="M670.893 951.307l30.231 25.905"
          stroke="#1FABAE"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M692.634 934.775l25.079 20.761"
          stroke="#F9D67C"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M697.577 923.795l28.092 23.575"
          stroke="#1FABAE"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M696.247 955.071l13.414 10.866"
          stroke="#E48358"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M365.595 973.53c-12.742-2.767-20.86-6.268-24.357-10.503-5.244-6.353-1.576-16.784-7.362-25.479-5.786-8.694-13.231-7.673-23.609-7.51-6.918.109-13.007-7.062-18.267-21.513"
          stroke="#160B2C"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          stroke="#E48358"
          strokeWidth="4"
          fill="#FFFDFC"
          transform="rotate(-110 345.758 966.092)"
          cx="345.758"
          cy="966.092"
          r="5"
        />
        <circle
          stroke="#F9D26F"
          strokeWidth="4"
          fill="#FFFDFC"
          transform="rotate(-110 333 937.291)"
          cx="333"
          cy="937.291"
          r="5"
        />
        <circle
          stroke="#69B0AE"
          strokeWidth="4"
          fill="#FFFDFC"
          transform="rotate(-110 301 924.291)"
          cx="301"
          cy="924.291"
          r="5"
        />
        <path
          d="M150.11 827.203c-2.895-13.594 13.807-27.107 28.807-16.429 13.577 9.666 15.457-4.413 30.635 1.497 14.8 5.763 8.843 28.975 2.804 36.007-2.68 3.12-8.296 5.222-9.752 5.585-13.153 3.28-15.425-14.691-26.006-12.592-11.32 2.245-23.594-.475-26.489-14.068z"
          fill="#FFAFA4"
          fillRule="nonzero"
        />
        <path
          d="M484.738 984.664c21.426-12.012 36.433-3.558 43 9.347 6.569 12.906 6.068 31.247-18.598 40.337-24.666 9.09-45.829-37.672-24.402-49.684z"
          fill="#F9D26F"
          fillRule="nonzero"
        />
        <g>
          <path
            d="M822.703 843.607c18.113-6.993 38.98 5.319 39.304 26.585.323 21.266-8.852 35.43-32.059 33.67-23.206-1.76-25.357-53.262-7.245-60.255z"
            fill="#F2D4DD"
            fillRule="nonzero"
          />
          <path
            d="M808.364 865.172c8.204 10.02 17.84 12.7 28.908 8.04 11.068-4.66 20.152-2.178 27.253 7.446"
            stroke="#FFFDFC"
            strokeWidth="8"
            strokeLinecap="round"
          />
          L{' '}
        </g>
        <g>
          <path
            d="M750.547 318.77c-23.482 21.871-58.84 8.543-70.412-16.752-11.571-25.294 2.037-52.462 27.906-60.547 25.87-8.084 65.989 55.427 42.506 77.299z"
            fill="#9775C1"
            fillRule="nonzero"
          />
          <g
            stroke="#160B2C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path d="M793.259 228.743l-19.242 13.667M758.718 202.137l-10.834 21.385M716.864 193.405l-.9 23.5M811.645 264.07l-22.136 7.615" />
          </g>
        </g>
      </g>
    </svg>
  );
}
